import React from "react"
import contactFormStyles from "./contactForm.module.scss"
export default function (props) {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [submitted, setSubmitted] = React.useState(false)
  const [subject] = React.useState(
    `Form submission from Clean Air Filter ${
      props.formLocation || "Contact"
    } form`
  )
  const sendEmail = e => {
    e.preventDefault()

    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": props.formName || "contact",
        name,
        subject,
        email,
        phone,
        message,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))

    // console.log(encode({
    //     "form-name": "contact",
    //     name,
    //     subject,
    //     email,
    //     phone,
    //     message
    // }))
  }

  return submitted ? (
    <p className="form-feedback">
      We received your message! A member of our team will touch base with you
      soon. Thank you!
    </p>
  ) : (
    <form
      name={props.formName || "contact"}
      // style={{minWidth:375}}
      data-netlify={true}
      netlify-honeypot="bot-field"
      method="post"
      className={contactFormStyles.wrapper}
      onSubmit={sendEmail}
    >
      <div style={{ display: "none" }}>
        <input
          type="text"
          id="inputFirstName"
          name="bot-field"
          aria-label="name"
        ></input>
      </div>

      <div style={{ display: "none" }}>
        <input
          type="hidden"
          id="inputSubject"
          name="subject"
          aria-label="subject"
          value={subject}
        ></input>
      </div>
      {/* <div style={{ display: 'none' }}>
            <input type="hidden" id="inputFormName" name="formName" aria-label="formName" value='contact'></input>
        </div> */}
      <div>
        <label htmlFor="inputName">Name</label>
        <input
          type="text"
          id="inputName"
          aria-label="inputName"
          name="name"
          value={name}
          onChange={e => {
            setName(e.currentTarget.value)
          }}
          required
        ></input>
      </div>

      <div>
        <label htmlFor="inputEmail">Email</label>
        <input
          type="email"
          id="inputEmail"
          aria-label="inputEmail"
          name="email"
          value={email}
          onChange={e => {
            setEmail(e.currentTarget.value)
          }}
          required
        ></input>
      </div>

      <div>
        <label htmlFor="inputPhone">Phone</label>
        <input
          id="phone"
          id="inputPhone"
          aria-label="inputPhone"
          name="phone"
          value={phone}
          onChange={e => {
            setPhone(e.currentTarget.value)
          }}
        ></input>
      </div>

      <div>
        <label htmlFor="inputMessage">Message</label>
        <textarea
          rows={4}
          id="inputMessage"
          aria-label="inputMessage"
          name="message"
          value={message}
          onChange={e => {
            setMessage(e.currentTarget.value)
          }}
        ></textarea>
      </div>
      <button type="submit" className="primary red">
        Send
      </button>
    </form>
  )
}
