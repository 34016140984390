import React from "react"
import Layout from "../../components/layout"
import ContactForm from "../../components/contactForm"
import ApplicationGuide from "./Application-Guide.pdf"
import ASABEX613 from "./ASABE-X613-Cabin-Filtration.pdf"
import CAF48R from "./CERTIFICATE OF COMPLIANCE CAF48R-4.pdf"
import MERV16 from "./MERV-16_ASABE-Response.pdf"
import NACAB from "./NA_CAB-Pressure-Cab-Protection-Levels.pdf"
import FailtureOfGravimetricTesting from "./Failture-of-Gravimetric-Testing.pdf"
import FSL from "./CAF Filter Life.pdf"
import VTP from "./CAF 6 Vapor Test Procedure R20204.pdf"
import PSFTP from "./CAF 3 Particulate System  Filter Test Procedure R20134.pdf"
import RFR from "./CAF 2 Recommended Flow Rate Test Procedure  R20134.pdf"
import FII from "./FPS Installation Instructions.pdf"
import MERV16NA from "./MERV-16.pdf"
import CPFT from "./CAF 4 Cab Protection Factors Template ISO R20245.pdf"
import FpsBasicIntallation from "./2021/FPS Basic Installation.pdf"
import TestQuality from "./2024/Test Protocol.pdf"
// import FpsBasicIntallationPpt from "./2021/FPS Basic Installation.pptx"

import FFPF from "./FIT FACTOR-PROTECTION FACTOR.pdf"
import AA from "./CAF 5 Abbreviations  Acronyms R20231.pdf"

import EmploymentApplication from "./2024/Application for Employment.pdf"

import { Helmet } from "react-helmet"
import data from "../../data/resources"
export default function Resources() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Resources | Clean Air Filter</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{data.content.section1.h1.head}</span>{" "}
            {data.content.section1.h1.tail}
          </span>
        </h1>
        <article className="w-60">
          <p>{data.content.section1.p1}</p>
        </article>
      </section>
      <article className="w-60">
        {/* <section>
                <h2>Photos</h2>
            </section>

            <section>
                <h2>Videos</h2>
            </section> */}

        <section>
          <h2>{data.content.section2.h2}</h2>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={"/files/240523 Application Guide.pdf"}
            >
              {data.content.section2.a1}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={EmploymentApplication}
              target={"_blank"}
              rel="noreferrer"
            >
              Employment Application
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={
                "/files/Enclosure Integrity - General Test Procedure - ESM R24051.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              Enclosure Integrity - General Test Procedure
            </a>
          </p>
          {/* <p><a className="download-link" target={'_blank'} rel="noreferrer" href={FGT}>{data.content.section2.a2}</a></p> */}
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={FpsBasicIntallation}
            >
              {data.content.section2.a15}
            </a>
          </p>
          {/* <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={"/downloads/FPS Basic Installation.pptx"}
            >
              {data.content.section2.a16}
            </a>
          </p> */}
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={FII}
            >
              {data.content.section2.a5}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={CAF48R}
            >
              {data.content.section2.a3}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={FSL}
            >
              {data.content.section2.a4}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={FFPF}
            >
              {data.content.section2.a13}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={ASABEX613}
            >
              {data.content.section2.a9}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={RFR}
            >
              {data.content.section2.a8}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={PSFTP}
            >
              {data.content.section2.a6}
            </a>
          </p>
          {/* <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={NACAB}
            >
              {data.content.section2.a2}
            </a>
          </p> */}
          <p>
            <a
              className="download-link"
              href={
                "/files/CAF 4 Cab Protection Factors Template ISO R23236.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              Cab Protection Factor Template
            </a>
          </p>{" "}
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={VTP}
            >
              {data.content.section2.a7}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={FailtureOfGravimetricTesting}
            >
              {data.content.section2.a10}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={MERV16NA}
            >
              {data.content.section2.a11}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={MERV16}
            >
              {data.content.section2.a12}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={TestQuality}
            >
              Test Protocol
            </a>
          </p>
          <p>
            <a
              className="download-link"
              target={"_blank"}
              rel="noreferrer"
              href={AA}
            >
              {data.content.section2.a14}
            </a>
          </p>
        </section>

        <section>
          <h2>Looking for something else?</h2>
          <div style={{ margin: "1rem 0" }}>
            <ContactForm
              formLocation="Resources"
              formName="resources"
            ></ContactForm>
          </div>
        </section>
      </article>
    </Layout>
  )
}
